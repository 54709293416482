<template> 
    <div
        class="c-expandable-wrapper qa-new-feature-zoom-in-wrapper"
        :class="{'is-expanded': expanded}"
        @click="expanded = true"
    >  
 
    
        <div v-if="expanded" class="c-close-button">
            <svg viewBox="0 0 24 24">
                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
        </div>

        <div v-if="!expanded" class="c-expand-button">
            <svg viewBox="0 0 24 24">
                <path d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z" />
            </svg>
        </div> 

        <img v-bind="$attrs" class="c-expandable-image qa-new-feature-zoom-in-image"/> 
    </div>
</template>

<script>
export default {
    Name: 'ImageZoom',
    props: {
        closeOnBackgroundClick: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            expanded: false,
            closeButtonRef: null
        }
    },
    watch: {
        expanded (status) {
            this.$nextTick(() => {
                if (status) {
                    this.cloned = this.$el.cloneNode(true)
                    this.closeButtonRef = this.cloned.querySelector('.c-close-button')
                    this.closeButtonRef.addEventListener('click', this.closeImage)
                    document.body.appendChild(this.cloned)
                    document.body.style.overflow = 'hidden' 
                    document.documentElement.style.overflow = 'hidden' 

                    this.cloned.addEventListener('touchmove', this.freeze, false)
                    if (this.closeOnBackgroundClick) {
                        this.cloned.addEventListener('click', this.onExpandedImageClick)
                    }
                    
                    setTimeout(() => {
                        this.cloned.style.opacity = 1 
                        this.cloned.lastChild.classList.add('is-visible') 
                    }, 0)
                } 
                
                else {
                    this.cloned.style.opacity = 0
                    this.cloned.removeEventListener('touchmove', this.freeze, false)
                    
                    if(this.closeOnBackgroundClick) {
                        this.cloned.removeEventListener('click', this.onExpandedImageClick)
                    }

                    setTimeout(() => {
                        this.closeButtonRef.removeEventListener('click', this.closeImage)
                        this.cloned.remove()
                        this.cloned = null
                        this.closeButtonRef = null 
                        document.body.style.overflow = 'auto'
                        document.documentElement.style.overflow = 'auto' 
                    }, 250)
                }
            })
        }
    },
    methods: {
        onExpandedImageClick(e) {
            e.stopPropagation()
            
            const image = this.cloned.querySelector('.c-expandable-image')

            const imagePosition = this.getRenderedSize(image.width, image.height, image.naturalWidth, image.naturalHeight)
            if (
                (e.clientX < imagePosition.left) ||
                (e.clientX > imagePosition.right) ||
                (e.clientY < imagePosition.top) ||
                (e.clientY > imagePosition.bottom)
            ) {
                this.expanded = false
            }
        },
        getRenderedSize (cWidth, cHeight, oWidth, oHeight) {
            const oRatio = oWidth > oHeight ? oWidth / oHeight : oHeight / oWidth
            const width = oWidth >= oHeight ? oRatio * cHeight : cWidth
            const height = oHeight > oWidth ? oRatio * cWidth: cHeight
            const left = (this.cloned.clientWidth - width) / 2
            const right = left + width
            const top = (this.cloned.clientHeight - height) / 2
            const bottom = top + height
            return { left, top, right, bottom }
        },
        closeImage(event) {
            this.expanded = false
            event.stopPropagation()
        },
        freeze(e) {
            e.preventDefault()
        }
    }
}


</script>

 