<template>
  <div>
    <div class="row my-0">
      <div class="col-12">
        <title-text
          :title="SHARED.WhatsNewPage.Title"
          class="qa-new-feature-title mb-4"
        />
        <p>
          {{ SHARED.WhatsNewPage.Description }}
          <a :href="`mailto:${SHARED.WhatsNewPage.Url}`">
            {{ SHARED.WhatsNewPage.Url }}
          </a>
        </p>
      </div>
    </div> 

    <recess-divider show-line /> 
  

    <div v-if="enableEdition" class="row">
      <div class="col-12 mb-5">
        <h3>{{SHARED.WhatsNewPage.AddNewItemsTitle}}</h3> 

        <NewFeatureComponent  
          :whats-new-items="defaultEmptyNewFeature"
          @save-new-items="saveNewItems"
        /> 
      </div>

      <div class="col-12 mb-3">
        <h3>{{SHARED.WhatsNewPage.ExistingItemsTitle}}</h3>
        <recess-divider show-line />
      </div> 

      <div class="col-12">
        <NewFeatureComponent  
          :whats-new-items="newFeatures"
          @save-new-items="saveNewItems"
        /> 
      </div> 
    </div>

    <div v-else class="row">
      <div class="col-12"> 
        <recess-card
          v-for="(newFeature, newFeatureIndex) in newFeatures"
          :key="`new-feature-${newFeatureIndex}`"
          :class="`qa-whats-new-item-${newFeatureIndex} mb-4`"
          variant="variant1"
        >
          <div class="row mt-0">
            <div class="col-12 d-flex">
              <p>{{ newFeature.startDate | formatDate }} </p>
              <span class="px-2" v-if="newFeature.endDate"> - </span>
              <p v-if="newFeature.endDate">{{ newFeature.endDate | formatDate }}</p>
            </div>
            
          </div>

          <div :class="['row my-0', { 'flex-row-reverse': newFeatureIndex % 2 !== 0 }]">
            <div class="col-12 col-md-6">
              <h4>{{ newFeature.title }}</h4>
              <p>{{ newFeature.description }}</p>
            </div>

            <div class="col-12 col-md-6">
                <div v-if="newFeature.imageUrl" class="c-whats-new__thumbnail">
                  <image-zoom
                      :src="newFeature.imageUrl"
                      :alt="newFeature.title" 
                      :close-on-background-click="true"
                  /> 
                </div>
            </div>
          </div> 
        </recess-card>
      </div>
    </div>


  </div>
</template>

<script>
import { SHARED } from "../../constants/EdumsSharedConstants.json";
import UserService from '../../../shared/services/UserService'  

import ImageZoom from "./ImageZoom.vue"; 

import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

import { postItem, deleteItem, patchItem, getItem } from '@/../../shared/api/SharedClient'
 

import genericImage from '../../../shared/src/assets/images/new-features/placeholder-image.jpg' 
import { articleErrorAction } from '../../constants/validationMessageHelper';
const TitleText = () => import("@/components/atoms/TitleText");
const NewFeatureComponent = () => import("./WhatsNewItem");

export default {
  name: "WhatsNew", 
  components: {
    TitleText,
    NewFeatureComponent,
    ImageZoom
  },
  data() {
    return { 
      SHARED, 
      API_CALL_URL_PATHS, 
      newFeatures: [],
      isAdministrator: false,
      userService: new UserService(),
      defaultEmptyNewFeature: [{
        title: null,
        description: null,
        image: null,
        imageUrl: genericImage,
        startDate: new Date().toJSON().slice(0, 10).split("-").reverse().join("-"), 
        endDate: null,
        isVisibleOnAccount: false,
        isVisibleOnProvider: false
      }]
    };
  }, 
  computed: {
    enableEdition() {
      return this.isAdministrator && process.env.VUE_APP_OIDC_CLIENT_ID === 'edums_core'
    }
  },
  async created() {
    this.isAdministrator = await this.userService.isAdministrator() 
  },
  async mounted() {
    await this.getWhatsNew()
  },
  methods: {
    async getWhatsNew() {  
      const params = { applicationName: process.env.VUE_APP_OIDC_CLIENT_ID}
      try {
        const response = await getItem(
          process.env.VUE_APP_DEVOPS_API_URL,
          API_CALL_URL_PATHS.whatsNew,
          null,
          null,
          params

        )
        if (!response) return
        this.newFeatures = response.filter((item) =>
          this.isCurrentDateInRange(item.startDate, item.endDate)
        );

      } catch (error) {
        console.error('Something went wrong while retrieving the whats new', error)
      }
    },
    isCurrentDateInRange(startDate, endDate) {
      const currentDate = new Date();
      const start = new Date(startDate);
      const end = endDate ? new Date(endDate) : null;

      const isAfterStart = currentDate >= start;

      const isBeforeEnd = end ? currentDate <= end : true;

      return isAfterStart && isBeforeEnd;
    },
    async postNewItems(newFeature) {    
      let toastNotification

      await postItem(
        process.env.VUE_APP_DEVOPS_API_URL,
        API_CALL_URL_PATHS.whatsNew,
        newFeature,
        false
      )
      .then(() => {  
          toastNotification = {
            type: 'success',
            message: SHARED.WhatsNewPage.Messages.Post.Success
          }   

          this.getWhatsNew()

          this.defaultEmptyNewFeature = [{
            title: null,
            description: null,
            image: null,
            imageUrl: genericImage,
            startDate: new Date(), 
            endDate: null
          }]
          
      }) 
      .catch(error => {
          toastNotification = {
            type: 'error',
            message: articleErrorAction('geactiveerd')
          }   

          console.error('Something went wrong while posting whats new', error)
      })
      
      this.$store.dispatch('toastNotificationModule/add', toastNotification, {
        root: true
      }) 
    },
    async deleteNewItems(newFeatureId) {
      let toastNotification

			await deleteItem(
        process.env.VUE_APP_DEVOPS_API_URL,
        `${API_CALL_URL_PATHS.whatsNew}/${newFeatureId}`,
        false
      )
      .then(() => {
        toastNotification = {
          type: 'success',
          message: SHARED.WhatsNewPage.Messages.Delete.Success
        }

        this.getWhatsNew() 
      })
      .catch((error) => {
        toastNotification = {
          type: 'error',
          message: articleErrorAction('verwijderd')
        }

        console.error('Something went wrong while deleting account', error)
      })

      this.$store.dispatch('toastNotificationModule/add', toastNotification, {
        root: true
      }) 
    }, 

    async patchNewItems(currentFeatureId, objectToBePatched) {
      let toastNotification 
      
      await patchItem(
        process.env.VUE_APP_DEVOPS_API_URL,
        API_CALL_URL_PATHS.whatsNew,
        currentFeatureId,
        objectToBePatched,
        false
      )
      .then(() => { 
        toastNotification = {
          type: 'success',
          message: SHARED.WhatsNewPage.Messages.Patch.Success
        }

        this.getWhatsNew()
         
      })
      .catch((error) => {
        toastNotification = {
          type: 'error',
          message: articleErrorAction('geüpdatet')
        } 

        console.error('Something went wrong while patching segment', error)
      })

      this.$store.dispatch('toastNotificationModule/add', toastNotification, {
        root: true
      })
    }, 

    async saveNewItems(payload) {     
      if(payload.itemToDelete) {  
        await this.deleteNewItems(payload.currentFeatureId)  
      }

      if(payload.itemToPost) { 
        await this.postNewItems(payload.objectToPost)  
      }

      if(payload.itemToPatch) {
        await this.patchNewItems(payload.currentFeatureId, payload.objectToPatch)  

      }

    }
  } 
};
</script>

